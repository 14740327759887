import axios from 'axios';

export const BASE_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5000'
  : 'https://server.genai.kunaltawatia.in/';

export const axios_instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  adapter: 'fetch',
});
