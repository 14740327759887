import { axios_instance } from './axios_instance';

export const generateTextBasedPromptResponse = (
  model: string,
  prompt: string,
  callback: (chunk: string) => void,
  err: (err: Error) => void,
) => {
  axios_instance.post('/text/stream', {
    model: model,
    prompt: prompt,
  }, {
    responseType: 'stream',
  }).then(async (response) => {
    const stream: ReadableStream = response.data;
    const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      const regex = /^data: (.*)\n\n$/;
      const match = value.match(regex);
      if (match) {
        const chunk = match[1];
        const unescaped_chunk = chunk.replaceAll('\\n', '\n');
        callback(unescaped_chunk);
      }
    }
  }).catch(error => {
    err(error);
  });
};
