import React from 'react';
import { Field, Form, Formik } from 'formik';
import { login, User } from '../services/auth_service';

type LoginPageComponentParams = {
  onLoginHook: () => void;
};
export default function LoginPage({ onLoginHook }: LoginPageComponentParams) {
  const INITIAL_VALUES: User = { username: 'kunaltawatia', password: '' };

  const handleSubmit = async (user: User) => {
    await login(user);
    onLoginHook();
  };

  return (
    <div className={'flex flex-col items-center p-8'}>
      <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form
            className={'login-form flex flex-col items-center space-y-4 p-4'}
          >
            <Field
              name={'username'}
              placeholder={'username'}
              className={'px-4 py-2'}
            />
            <Field
              type={'password'}
              name={'password'}
              placeholder={'password'}
              className={'px-4 py-2'}
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className={'px-4 py-2'}
            >
              {isSubmitting ? 'Wait' : 'Login'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
