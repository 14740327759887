import { axios_instance } from './axios_instance';

export interface User {
  username: string;
  password: string; // TODO: How to handle input-only fields in APIs?
}

export const getUser = async () => {
  try {
    const response = await axios_instance.get<{ user: User }>('/auth/user');
    const { user } = response.data;
    return user;
  } catch {
    return null;
  }
};

export const login = async (user: User) => {
  const response = await axios_instance.post<{ user: User }>(
    '/auth/login',
    user,
  );
  const { user: authenticatedUser } = response.data;
  return authenticatedUser;
};
