import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router';

import { getUser, User } from './services/auth_service';
import LoginPage from './pages/LoginPage';
import Loader from './components/Loader';
import TextOnlyPromptPage from './pages/TextOnlyPromptPage';
import FileBasedPromptPage from './pages/FileBasedPromptPage';

function App() {
  // TODO: Is there a generic way for loading states while fetching data?
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    loadUser().then(() => {
    });
  }, []);

  const loadUser = async () => {
    setLoading(true);
    const user = await getUser();
    setUser(user);
    setLoading(false);
  };

  if (loading) return <Loader />;

  if (!user) return <LoginPage onLoginHook={loadUser} />;

  return (
    <Router>
      <Routes>
        <Route index element={<TextOnlyPromptPage />} />
        <Route path={'/file'} element={<FileBasedPromptPage />} />
      </Routes>
    </Router>
  );
}

export default App;
