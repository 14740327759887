import { KeyboardEvent, useState } from 'react';
import { MagnifyingGlassIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';

import Markdown from '../components/Markdown';
import Textarea from '../components/Textarea';
import { generateTextBasedPromptResponse } from '../services/genai_service';

enum Model {
  GEMINI_FLASH = 'GEMINI_FLASH',
  GEMINI_PRO = 'GEMINI_PRO',
  CLAUDE_SONNET = 'CLAUDE_SONNET',
  CLAUDE_HAIKU = 'CLAUDE_HAIKU',
}

export default function TextOnlyPromptPage() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [shouldFormatResponse, setShouldFormatResponse] = useState(true);
  const [model, setModel] = useState(Model.GEMINI_FLASH);


  const streamResponse = () => {
    const LOADING_PLACEHOLDER = '...';
    setResponse(LOADING_PLACEHOLDER);
    generateTextBasedPromptResponse(
      model,
      prompt,
      (chunk: string) => {
        setResponse(response => {
          if (response === LOADING_PLACEHOLDER) {
            return chunk;
          }
          return response + chunk;
        });
      },
      (error: Error) => {
        setResponse(error.message);
      },
    );
  };

  const toggleResponseFormatting = () =>
    setShouldFormatResponse(!shouldFormatResponse);

  const clearScreen = () => {
    setPrompt('');
    setResponse('');
  };

  const searchWithGoogle = () => {
    const url = `https://www.google.com/search?q=${encodeURIComponent(prompt)}`;
    window.open(url, '_blank');
  };

  const handleKeyMapping = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.metaKey) {
      switch (event.key) {
        case 'Enter':
          return streamResponse();
        case 'g':
          return searchWithGoogle();
        default:
          return;
      }
    }

    if (event.ctrlKey) {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          return streamResponse();
        case 'f':
          toggleResponseFormatting();
          return;
        case 'd':
          clearScreen();
          return;
        case '1':
          setModel(Model.GEMINI_FLASH);
          return;
        case '2':
          setModel(Model.GEMINI_PRO);
          return;
        case '3':
          setModel(Model.CLAUDE_SONNET);
          return;
        case '4':
          setModel(Model.CLAUDE_HAIKU);
          return;
        default:
      }
    }
  };

  return <div
    className="flex h-screen flex-col space-y-4 p-4 text-gray-300"
    onKeyDown={handleKeyMapping}
  >
    <div
      tabIndex={0}
      className="neu-up h-3/5 flex-grow overflow-auto rounded-lg p-4"
    >
      <Markdown content={response} shouldFormat={shouldFormatResponse} />
    </div>
    <div className="flex items-center justify-between">
      <select
        tabIndex={0}
        value={model}
        onChange={e => setModel(e.target.value as Model)}
        className="neu-down appearance-none p-2"
      >
        <option value={Model.GEMINI_FLASH}>Gemini Flash</option>
        <option value={Model.GEMINI_PRO}>Gemini Pro</option>
        <option value={Model.CLAUDE_SONNET}>Claude Sonnet</option>
        <option value={Model.CLAUDE_HAIKU}>Claude Haiku</option>
      </select>
      <div className={'space-x-2'}>
        <button
          tabIndex={0}
          onClick={searchWithGoogle}
          className="neu-up rounded-full p-2"
        >
          <MagnifyingGlassIcon className={'size-4'} />
        </button>
        <button
          tabIndex={0}
          onClick={streamResponse}
          className="neu-up rounded-full p-2"
        >
          <PaperAirplaneIcon className="size-4" />
        </button>
      </div>
    </div>
    <div className="neu-down flex-grow rounded-lg p-4">
      <Textarea value={prompt} setValue={value => setPrompt(value)} />
    </div>
  </div>;
}
