import React from 'react';

export default function Loader() {
  return (
    <div className="loader">
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  );
}
